// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgELmiIhFO5JCKWCXtjLemLDT5zTJOr70",
  authDomain: "form-web-8029c.firebaseapp.com",
  projectId: "form-web-8029c",
  storageBucket: "form-web-8029c.appspot.com",
  messagingSenderId: "469110441118",
  appId: "1:469110441118:web:bbf21a686c35084788a78f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
