import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import secLogo from "../assets/secondarylogo.png";
import Footer from "./Footer";
import Description from "./Description";
import InnerBanner from "./InnerBanner";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebase";
import Navbar from "./Navbar";
import Steps from "./Steps";

const MainForm = () => {
  const [percentage, setPercentage] = useState(null);
  const initialValues = {
    mobileNumber: "",
    confirmmobileNumber: "",
    dueamount: "",
    pincode: "",
  };
  const navigate = useNavigate();

  const handlePaste = (e) => {
    e.preventDefault();
    alert("Pasting is not allowed in this field");
  };

  const validationSchema = Yup.object({
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number must contain only numbers")
      .min(10, "Mobile Number must be at least 10 digits")
      .max(11, "Mobile Number must not be greater than 11 digits")
      .required("Mobile Number is required"),
    confirmmobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number must contain only numbers")
      .min(10, "Mobile Number must be at least 10 digits")
      .max(11, "Mobile Number must not be greater than 11 digits")
      .oneOf([Yup.ref("mobileNumber"), null], "Mobile Numbers do not match")
      .required("Confirm Mobile Number is required"),
    dueamount: Yup.number()
      .min(10, "Due Amount must be equal to or greater than 10")
      .max(200, "Due Amount must be equal to or less than 200")
      .required("Due Amount is required"),

    pincode: Yup.string()
      .required("4 Digit Pin Code is required")
      .matches(/^[0-9]{4}$/, "Pin Code must be 4 digits"),
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const docRef = doc(db, "TaxPercentage", "Lk1RlHWnVTYpqxTpar4E");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let zoop = docSnap.data();
      console.log("sps", zoop);
      setPercentage({ ...zoop });
      console.log("dataz", zoop);
      console.log(percentage);
    } else {
      console.log("No such document!");
    }
  };
  const onSubmit = async (values) => {
    localStorage.setItem(
      "data",
      JSON.stringify({
        ...values,
        ...percentage,
      })
    );

    navigate("/Payment");
  };

  return (
    <>
      <div className="bg-[#373737] min-h-screen flex flex-col">
        <Navbar />
        <div className="flex-grow flex justify-center items-center py-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="bg-black flex flex-col items-center max-w-[1000px] py-5 w-full px-8 rounded-xl">
              <div className="bg-[#ec7800] flex w-full rounded-xl justify-center">
                <img className="w-[20%]" src={secLogo} alt="" />
              </div>
              <h2 className="text-3xl font-bold text-white my-6">
                Boost Mobile Refill
              </h2>
              <div className="p-10 rounded-xl">
                <div className="max-w-[1000px] w-full py-4">
                  <p className="text-white font-bold">
                    Let's start with the phone number you want to pay
                  </p>
                  <p className="text-white">Enter Mobile Number</p>
                  <div className="relative">
                    <div className="absolute top-[4px] left-[3.5px] text-black mt-3">
                      +1
                    </div>
                    <Field
                      type="tel"
                      name="mobileNumber"
                      className="w-full mt-2 rounded-2xl py-2 px-4 pl-[35px] text-black"
                    />
                    <ErrorMessage
                      name="mobileNumber"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="max-w-[1000px] w-full py-4">
                  <p className="text-white">Confirm Mobile Number</p>
                  <div className="relative">
                    <div className="absolute top-[4px] left-[3.5px] text-black mt-3">
                      +1
                    </div>
                    <Field
                      type="tel"
                      onPaste={handlePaste}
                      name="confirmmobileNumber"
                      className="w-full mt-2 rounded-2xl py-2 px-4 pl-[35px] text-black"
                    />
                    <ErrorMessage
                      name="confirmmobileNumber"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <hr className="text-white w-full my-6" />
                <div className="max-w-[1000px] w-full py-4">
                  <p className="text-white font-bold">
                    Please let us know how much money should be added to your
                    boost mobile number.
                  </p>
                  <p className="text-white">Enter Due Amount</p>
                  <Field
                    type="tel"
                    name="dueamount"
                    className="w-full mt-2 rounded-2xl py-2 px-4 text-black"
                  />
                  <ErrorMessage
                    name="dueamount"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>
                <div className="max-w-[1000px] w-full py-4">
                  <p className="text-white">Enter 4 Digit Pin Code</p>
                  <Field
                    type="tel"
                    name="pincode"
                    className="w-full mt-2 rounded-2xl py-2 px-4 text-black"
                  />
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>
              </div>
              <button
                className="text-white bg-[#ec7800] px-6 py-3 w-3/5 hover:bg-secondary rounded-2xl font-bold mt-6"
                onClick={() => { }}
                type="submit"
              >
                Next
              </button>
            </Form>
          </Formik>
        </div>
      </div>

      <Steps />

      <InnerBanner />
      <Description />
      <Footer />
    </>
  );
};

export default MainForm;
