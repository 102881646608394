import React from "react";
import { phonecard } from "../assets";

const InnerBanner = () => {
  return (
    <div className=" w-full h-full py-10 relative my-20 text-white">
      <div className="flex container lg:flex-nowrap flex-wrap justify-center py-16 items-center lg:justify-between mx-auto px-4">
        <div className="max-w-[600px] w-full">
          <h2 className="text-secondary text-4xl font-bold">
            {" "}
            Why Choose Us ?{" "}
          </h2>
          <p className="px-2 py-1  text-2xl">
            Our online top-up service is the quickest and easiest way to refill
            your phone with a credit or debit card. Our 24/7 support makes it
            quick and simple for you to get access to your loved ones even
            faster
          </p>
        </div>
        <div className=" max-w-[500px] w-full">
          <img
            src={phonecard}
            alt="phonecard"
            className="lg:absolute top-[-15%] bottom-[-15%] w-[400px]"
          />
        </div>
      </div>
    </div>
  );
};

export default InnerBanner;
