import React from "react";
import {
  fullsecuredssl,
  safecheckout,
  sslsecured,
  thumbsbanner,
} from "../assets";

const Description = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h2 className="text-secondary max-w-[470px] rounded-xl p-1 text-center w-full font-bold text-5xl">
        You are Safe with Us
      </h2>
      <div className="py-5">
        <p className="text-white font-bold text-3xl">
          {" "}
          Without Strong encryption , you will be spied on systematically by
          lots of people. We Strongly Use Encryption, your security is our first
          priority.
        </p>
      </div>
    </div>
  );
};

export default Description;
