import React from "react";

const Steps = () => {
  return (
    <div className="mt-10 ">
      <h1 className="mb-4 text-center font-extrabold text-xl text-white">
        STEPS
      </h1>
      <div className="flex flex-col md:flex-row container mx-auto px-4">
        <div className="md:w-1/3 w-full text-center px-6 mb-4 md:mb-0">
          <div className="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
            <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-120v40h400v-40H280Zm0-80h400v-480H280v480Zm0-560h400v-40H280v40Zm0 0v-40 40Zm0 640v40-40Z" />
              </svg>
            </div>
            <div className="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-bold text-sm">Step No 1</h2>
              <p className="text-xs text-gray-600">Enter Your Mobile Number</p>
            </div>
          </div>
        </div>

        <div className="flex-1 flex items-center md:my-0 my-4 md:rotate-0 rotate-90 justify-center">
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
          </svg>
        </div>

        <div className="md:w-1/3 w-full text-center px-6 mb-4 md:mb-0">
          <div className="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
            <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-120v-85l212-212 85 85-212 212h-85ZM120-320v-80h280v80H120Zm685-40-85-85 29-29q11-11 28-11t28 11l29 29q11 11 11 28t-11 28l-29 29ZM120-480v-80h440v80H120Zm0-160v-80h440v80H120Z" />
              </svg>
            </div>
            <div className="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-bold text-sm">Step No 02</h2>
              <p className="text-xs text-gray-600">Enter your due amount</p>
            </div>
          </div>
        </div>

        <div className="flex-1 flex items-center md:my-0 my-4 md:rotate-0 rotate-90 justify-center">
          <svg
            className=""
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
          </svg>
        </div>

        <div className="md:w-1/3 w-full text-center px-6">
          <div className="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
            <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" />
              </svg>
            </div>
            <div className="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-bold text-sm">Step No 03</h2>
              <p className="text-xs text-gray-600">Check out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
