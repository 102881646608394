import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Contactus = () => {
  return (
    <>
    <Navbar />
    <div className="container mx-auto my-8 p-8 bg-gray-100 rounded-lg shadow-md">
    <h1 className="text-3xl font-bold mb-4">GET IN TOUCH</h1>
    <p className="text-gray-700 mb-6">
      Give us a call or drop by anytime, we answer all enquiries within 24 hours.
    </p>
    
    <div className="flex items-center mb-6">
      <div className="w-1/2">
        <h2 className="text-xl font-bold mb-2">Company Email</h2>
        <p className="text-gray-700">Contact@payboostbills.com</p>
      </div>
    </div>

    <div className="flex items-center">
      <div className="w-1/2">
        <h2 className="text-xl font-bold mb-2">Contact Us</h2>
        <p className="text-gray-700">
              <a href="tel:+13478093331">+1 (347) 809-3331</a>
            </p>
      </div>
    </div>
  </div>
  <Footer/>
    </>
  )
}

export default Contactus