import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const ReturnAndRefundPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto my-8 p-8 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Returns and Refunds Policy</h1>
      <p className="text-gray-700 mb-6">
        Thank you for choosing Pay Boost Bills for your mobile recharge needs. We value your satisfaction and strive to provide you with a seamless experience. Please read this Returns and Refunds Policy carefully.
      </p>
      <ol className="list-decimal ml-6 mb-6">
        <li className="text-gray-700 mb-4">
          <strong>Refund Timeframe:</strong> Your payment is only eligible for a refund before posting to your carrier, once it has been posted to Boost Mobile it cannot be refunded.
        </li>
        <li className="text-gray-700 mb-4">
          <strong>Eligibility for Refund:</strong>
          <ul className="list-disc ml-6">
            <li>The recharge has not been posted to the Boost Mobile account associated with the transaction.</li>
            <li>Payment has not been posted to Boost mobile for some reason.</li>
          </ul>
        </li>
        <li className="text-gray-700 mb-4">
          <strong>Non-Refundable Transactions:</strong> Transactions that have already been posted to the Boost Mobile account associated with the recharge are considered non-refundable. Once a recharge is successfully posted to the Boost Mobile account, it cannot be refunded.
        </li>
        <li className="text-gray-700 mb-4">
          <strong>How to Request a Refund:</strong>
          <ol className="list-decimal ml-6">
            <li>(a) Contact Us: Reach out to our customer support team at contact@payboostbills.com or +1 (347) 809-3331 within the 7-day refund period.</li>
            <li>(b) Provide Transaction Details: To process your request, we may require details such as your transaction ID, email address, and any other relevant information.</li>
            <li>(c) Verification: We will verify your eligibility for a refund based on the criteria outlined in Section 2.</li>
            <li>(d) Refund Processing: If your request is approved, we will process the refund in accordance with our standard procedures.</li>
          </ol>
        </li>
        <li className="text-gray-700 mb-4">
          <strong>Refund Method:</strong> Refunds, when approved, will be processed using the original payment method used for the transaction. Please allow 5 business days for the refund to appear in your account, depending on your payment provider's policies.
        </li>
        <li className="text-gray-700">
          <strong>Contact Information:</strong> If you have any questions or need assistance with the refund process, please do not hesitate to contact our customer support team at contact@payboostbill.com or +1 (347) 809-3331.
        </li>
      </ol>
      <p className="text-gray-700 mb-6">
        <strong>Changes to the Policy:</strong> Pay Boost Bills reserves the right to modify or update this Returns and Refunds Policy at any time. Changes will be posted on our website, and it is your responsibility to review this policy periodically.
      </p>
    </div>

    <div className="container mx-auto my-8 p-8 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Cookies Policy</h1>
      <p className="text-gray-700 mb-6">
        <strong>1. What Are Cookies?</strong> Cookies are small text files that are stored on your computer or mobile device when you visit a website. They help us recognize your device and remember information about your visit, such as your preferences and settings.
      </p>
      <p className="text-gray-700 mb-6">
        <strong>2. How We Use Cookies</strong>
      </p>
      <ul className="list-disc ml-6 mb-6">
        <li><strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website.</li>
        <li><strong>Performance Cookies:</strong> These cookies help us understand how visitors interact with our website. They collect information about which pages are visited most frequently and any error messages received.</li>
        <li><strong>Functional Cookies:</strong> These cookies allow the website to remember your choices and preferences, such as language or region, to provide an enhanced user experience.</li>
        <li><strong>Advertising Cookies:</strong> We may use these cookies to deliver relevant advertisements to you based on your interests and browsing behavior.</li>
      </ul>
      <p className="text-gray-700 mb-6">
        <strong>3. Types of Cookies We Use</strong>
      </p>
      <ul className="list-disc ml-6 mb-6">
        <li><strong>(a) Session Cookies:</strong> These are temporary cookies that are erased from your device when you close your web browser.</li>
        <li><strong>(b) Persistent Cookies:</strong> These cookies remain on your device for a specified period or until you delete them. They are activated each time you visit the website that created the cookie.</li>
      </ul>
      <p className="text-gray-700 mb-6">
        <strong>4. Managing Cookies</strong>
      </p>
      <p className="text-gray-700 mb-6">
        You can control and manage cookies through your browser settings. You can choose to block or delete cookies, but please note that doing so may affect your experience on our website.
      </p>
      <p className="text-gray-700 mb-6">
        <strong>5. Third-Party Cookies</strong>
      </p>
      <p className="text-gray-700 mb-6">
        Our website may use cookies from third-party service providers, such as analytics and advertising partners. These cookies are subject to the privacy policies of those third parties.
      </p>
      <p className="text-gray-700 mb-6">
        <strong>6. Updates to this Policy</strong>
      </p>
      <p className="text-gray-700 mb-6">
        We may update this Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The latest version will be posted on our website.
      </p>
      <p className="text-gray-700">
        <strong>7. Contact Us</strong>
      </p>
      <p className="text-gray-700">
        If you have any questions about our use of cookies or this Cookies Policy, please contact us at: contact@payboostbills.com
      </p>
    </div>

      <Footer />
    </>
  );
};

export default ReturnAndRefundPolicy;
