import React from "react";
// import { pbblogo } from "../assets";
import newlogo from ".././assets/newlogo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const terms = () => {
    navigate("/TermsAndCondition");
  };
  const refund = () => {
    navigate("/ReturnAndRefundPolicy");
  };
  return (
    <div>
      <footer className="bg-black pt-11 text-white ">
        <div className=" container mx-auto px-4 grid lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 place-content-between py-10 gap-4">
          <div className="max-w-[500px] w-full  px-4">
            <a href="/">
              <img src={newlogo} alt="logo" className="w-[180px]" />
            </a>

            <p>
              {" "}
              is an authorized national payment center. As an authorized
              national payment center it is pur duty to provide you with the
              highest customer service.
            </p>
          </div>
          <div className="max-w-[500px] w-full xl:border-l  xl:border-white px-4">
            <h2 className=" font-bold  text-secondary text-2xl py-6 ">
              {" "}
              Customer Support
            </h2>
            <div className="flex  items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>

              <p className="px-2">support@myboostrecharge.com</p>
            </div>
            <div className="flex  items-center  py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>

              <p className="px-2">+1 (276) 253-8158</p>
            </div>
          </div>

          <div className="max-w-[500px] w-full xl:border-l  xl:border-white px-4 text-start">
            <h2 className=" font-bold  text-secondary text-2xl py-6 ">
              {" "}
              Policy
            </h2>

            <ul>
              <li onClick={terms} className="cursor-pointer">
                Terms and condition{" "}
              </li>
            </ul>
            <ul>
              <li onClick={refund} className="cursor-pointer">
                Return and Refunds Policy{" "}
              </li>
            </ul>

            {/* <p>Return and Refunds Policy</p> */}

            <ul>
              <li onClick={terms} className="cursor-pointer">
                Privacy Policy{" "}
              </li>
            </ul>
            {/* <p>Privacy Policy</p> */}
            <ul>
              <li onClick={refund} className="cursor-pointer">
                Cookies Policy{" "}
              </li>
            </ul>
          </div>

          <div className="max-w-[500px] w-full xl:border-l  xl:border-white px-4 text-start">
            <h2 className=" font-bold  text-secondary text-2xl py-6 ">
              {" "}
              Quick Links
            </h2>
            <div>
              <a href="/aboutus">About Us</a>
            </div>

            <div>
              <a href="/contactus">Contact Us</a>
            </div>

            <a href="faqs">FAQ'S</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
