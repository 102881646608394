import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const AboutUs = () => {
  return (
    <>
    <Navbar />

    <div className="container mx-auto my-8 p-8 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Our Service Mission</h1>
      <p className="text-gray-700 mb-6">
        Pay Boost Bills gives services by using our top-up system. Our goal is to help users get access and connectivity. Our team understands the importance of providing our customers with a fast and secure solution to refill their Boost mobile phones and works around the clock to guarantee a hassle-free experience. That is the reason Take care of Your Mobile Bill makes it simpler than any time in recent memory to top off your Boost Mobile account right away!
      </p>
      <p className="text-gray-700 mb-6">
        We offer a fast, helpful way for you to get to the most recent highlights on Boost Mobile. Our online Boost Mobile top-up service is the most convenient way to refill your Boost Mobile account with a credit or debit card. Our 24/7 support makes it simple for you to get access to your loved ones even faster. Feel at ease placing a refill order for Boost Mobile that we offer, since we specialize in delivering your recharge instantly.
      </p>
      <p className="text-gray-700 mb-6">
        Your mobile phone company is the only one who can stop your bill from going up. When they don’t increase your phone plan, they know you’re taking advantage of their fast, friendly customer service just to get a better deal. If a customer has a good relationship with their carrier and follows few steps to avoid recurrence, chances are that customer will get their refill on time and without any hassle.
      </p>
    </div>
    <Footer />
    </>
  )
}

export default AboutUs