import React, { useState, useEffect } from "react";
// import { pbblogo } from "../assets";
import newlogo from ".././assets/newlogo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="bg-black p-4">
      <div className="container mx-auto flex items-start md:items-center justify-between">
        {/* Logo */}
        <div className="flex items-center flex-col">
          <div>
            <a href="/">
              <img
                src={newlogo}
                alt="newlogo"
                className="w-[100px] md:w-[100px]"
              />
            </a>
          </div>
          <div
            className={`md:hidden ${isMenuOpen ? "flex flex-col" : "hidden"}`}
            // Added 'flex-col' class to change the direction to column layout
          >
            <Link to={"/aboutus"} className="text-white font-bold   block p-2">
              About Us
            </Link>
            <Link to={"/contactus"} className="text-white font-bold block p-2">
              Contact Us
            </Link>
            <Link to={"/faqs"} className="text-white font-bold   block p-2">
              FAQs
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        {/* Mobile Menu Button (Conditionally Rendered) */}
        {isMobile ? (
          <div className="md:hidden mt-4">
            {isMenuOpen ? (
              <button className="text-white" onClick={closeMenu}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            ) : (
              <button className="text-white" onClick={toggleMenu}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            )}
          </div>
        ) : null}

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-4 mr-5">
          <Link to={"/aboutus"} className="text-white font-semibold text-lg ">
            About Us
          </Link>
          <Link to={"/contactus"} className="text-white font-semibold text-lg ">
            Contact Us
          </Link>
          <Link to={"/faqs"} className="text-white font-semibold text-lg ">
            FAQs
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
