import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const TermsAndCondition = () => {
  return (
    <>
      <Navbar />
      <div className='container mx-auto px-4 text-white '>
        <h2 className='text-5xl text-center font-bold py-10 '>
          {' '}
          Terms and Conditions
        </h2>
        <div className='pb-20 pt-6 '>
          <p>
            These Terms and Conditions ("Terms") govern your use of the pay
            boost bills website, products, and services (collectively referred
            to as "Services"). By accessing or using our Services, you agree to
            comply with and be bound by these Terms. If you do not agree to
            these Terms, please do not use our Services.
          </p>

          <h2 className='font-bold text-xl'>1. Acceptance of Terms</h2>
          <p>
            By using our Services, you confirm that you have read, understood,
            and agree to these Terms, and you represent and warrant that you
            have the legal capacity to enter into this agreement.
          </p>

          <h2 className='font-bold text-xl'>2. Changes to Terms</h2>
          <p>
            pay boost bills reserves the right to modify, revise, or update
            these Terms at any time. Any changes will be effective immediately
            upon posting on our website. Your continued use of the Services
            after any changes constitute your acceptance of the updated Terms.
          </p>

          <h2 className='font-bold text-xl'>3. Use of Services</h2>
          <p>
            (a) Eligibility: You must be at least 16 years old to use our
            Services.
          </p>
          <p>
            (b) Account Registration: Some Services may require you to create an
            account. You agree to provide accurate, current, and complete
            information during the registration process and to update such
            information to keep it accurate, current, and complete.
          </p>
          <p>
            (c) User Conduct: You agree not to engage in any prohibited
            activities while using our Services, including but not limited to:
          </p>
          <ul>
            <li>Violating any applicable laws or regulations.</li>
            <li>Impersonating any person or entity.</li>
            <li>
              Uploading, posting, transmitting, or distributing any content that
              is harmful, illegal, defamatory, obscene, or infringing upon the
              rights of others.
            </li>
            <li>
              Attempting to gain unauthorized access to our systems or user
              accounts.
            </li>
          </ul>

          <h2 className='font-bold text-xl'>4. Intellectual Property</h2>
          <p>
            (a) Ownership: All content, trademarks, logos, and intellectual
            property associated with pay boost bills are owned by pay boost
            bills or its licensors and are protected by copyright and other
            intellectual property laws.
          </p>
          <p>
            (b) Limited License: You are granted a limited, non-exclusive,
            non-transferable license to access and use our Services for
            personal, non-commercial purposes. This license does not grant you
            any ownership rights.
          </p>

          <h2 className='font-bold text-xl'>5. Privacy</h2>
          <p>
            Your use of our Services is subject to our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
            By using our Services, you consent to the practices described in the
            Privacy Policy.
          </p>
        </div>
      </div>

      <div className='container mx-auto p-6 text-white'>
        <h1 className='text-4xl font-bold mb-4 '>Privacy Policy</h1>

        <h2 className='text-xl font-semibold mt-4'>
          1. Information We Collect
        </h2>
        <p>
          We may collect personal information such as your name, email address,
          and other data necessary to provide our services. We may also collect
          non-personal information, such as device information and usage data.
        </p>

        <h2 className='text-xl font-semibold mt-4'>
          2. How We Use Your Information
        </h2>
        <p>
          We use your information to provide and improve our services,
          communicate with you, and ensure compliance with legal requirements.
        </p>

        <h2 className='text-xl font-semibold mt-4'>3. Data Security</h2>
        <p>
          We implement security measures to protect your information from
          unauthorized access and disclosure.
        </p>

        <h2 className='text-xl font-semibold mt-4'>4. Cookies and Analytics</h2>
        <p>
          We may use cookies and analytics tools to enhance your experience and
          gather data about our website's usage.
        </p>

        <h2 className='text-xl font-semibold mt-4'>5. Third-Party Links</h2>
        <p>
          Our services may contain links to third-party websites. We are not
          responsible for their privacy practices.
        </p>

        <h2 className='text-xl font-semibold mt-4'>6. Your Choices</h2>
        <p>
          You can update or delete your personal information or opt out of
          communications at any time.
        </p>

        <h2 className='text-xl font-semibold mt-4'>7. Children's Privacy</h2>
        <p>
          Our services are not intended for children under 13. We do not
          knowingly collect their personal information.
        </p>

        <h2 className='text-xl font-semibold mt-4'>
          8. Updates to this Policy
        </h2>
        <p>
          We may update this Privacy Policy. The latest version will be posted
          on our website.
        </p>

        <h2 className='text-xl font-semibold mt-4'>9. Contact Us</h2>
        <p>
          For questions or concerns about your privacy, please contact us at{' '}
          <a className='text-blue-500' href='mailto:contact@payboostbills.com'>
            contact@payboostbills.com
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
