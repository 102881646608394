import "./App.css";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import MainForm from "./components/MainForm";
import SpecialForm from "./components/SpecialForm";
import * as Yup from "yup";
import Payment from "./components/Payment";
import { useFormik } from "formik";
import TermsAndCondition from "./components/TermsAndCondition";
import ReturnAndRefundPolicy from "./components/ReturnAndRefundPolicy";
import Faqs from "./components/Faqs";
import AboutUs from "./components/AboutUs";
import Contactus from "./components/Contactus";
function App() {
  let zebra = false;
  const initialValues = {
    mobileNumber: "",
    confirmmobileNumber: "",
    dueamount: "",
    pincode: "",
  };

  const validationSchema = Yup.object({
    mobileNumber: Yup.string().required("Mobile Number is required"),
    confirmmobileNumber: Yup.string()
      .oneOf([Yup.ref("mobileNumber"), null], "Mobile Numbers do not match")
      .required("Confirm Mobile Number is required"),
    dueamount: Yup.string().required("Due Amount is required"),
    pincode: Yup.string()
      .required("4 Digit Pin Code is required")
      .matches(/^\d{4}$/, "Pin Code must be 4 digits"),
  });

  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });
  return (
    <div className="bg-[#373737]">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainForm formik={formik} />} />
          <Route path="/Payment" element={<Payment formik={formik} />} />
          <Route
            path="/kljm"
            element={zebra ? <SpecialForm /> : <Navigate to="/" />}
          />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
          <Route
            path="/ReturnAndRefundPolicy"
            element={<ReturnAndRefundPolicy />}
          />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<Contactus />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
