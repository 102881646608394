import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const faqData = [
  {
    question: "What happens if my order is cancelled, or fails?",
    answer:
      "If your order is cancelled or fails on our end, you will receive a full refund automatically. We do not charge you unless we can successfully process your phone bill refill order.",
  },
  {
    question: "How is my payment processed?",
    answer:
      "Your payment is processed through our secure checkout system which encrypts your information. This leads to a secure and easy transaction for you",
  },
  {
    question: "What if I don't have an online account with my phone company?",
    answer:
      "Nothing to worry about, even without an online account with your phone company, you can process your phone bill refill with us. All we need to process is the phone number you want to refill on. The rest, we will handle.",
  },
  {
    question: "What times are phone bill recharges processed?",
    answer:
      "Your phone bill recharge will be processed instantly at any time of the day. We operate 24/7/365 to make sure your phone service is always accessible.",
  },
  {
    question: "I have an issue with my refill, what should I do?",
    answer:
      "Unfortunately, issues can sometimes arise which are out of our control. If you have an issue with your refill processed through us, please contact us immediately via email (contact@payboostbills.com) or phone (347) 809-3331",
  },
  {
    question: "Can I pay someone else's phone bill?",
    answer:
      "Yes, we can process a refill for someone else's phone number without any issues. You can gift a payment, all you need to do is provide their phone number when going through our easy 4 step process.",
  },
];

const Faqs = () => {
  return (
    <>
      <Navbar />
      <div className="container max-w-[1200px] w-full mx-auto px-4 py-5  ">
        <h2 className="text-5xl my-10  rounded-lg py-1 text-secondary font-bold text-center max-w-[700px] w-full mx-auto">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          {faqData.map((faq, index) => (
            <details
              key={index}
              className="group rounded-2xl bg-white  shadow-2xl p-4 [&_summary::-webkit-details-marker]:hidden"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
                <h2 className="font-bold text-xl">{faq.question}</h2>

                <span className="relative h-5 w-5 shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">{faq.answer}</p>
            </details>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
